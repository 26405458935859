import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";

import colours from "../../../styles/colours";
import gStyles from "../../../styles/GenericStyles";
import NavMenuItem from "./NavMenuItem";
import useNavLinks from "./useNavLinks";

import { userHasPermission } from "utils/entity/user";

import { useScrollPosition } from "hooks/useScrollPosition";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import HeaderStyles from "styles/HeaderStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const NavMenu = (props) => {
  const { transparent, user } = props;

  const scrollPosition = useScrollPosition(transparent);

  const switchToWhiteStyles = useMemo(() => {
    if (transparent && scrollPosition > 18) {
      return true;
    }
    return false;
  }, [scrollPosition, transparent]);

  const baseStyles = useMemo(
    () => [
      {
        container: {
          ...gStyles.gradientBackground,
          zIndex: 15,
          width: "100%",
          transition: "ease-out 0.2s",
        },
        whiteBackground: {
          background: colours.white,
          boxShadow: "1px 6px 5px 0px rgba(0, 0, 0, 0.12)",
        },
        inner: {
          ...HeaderStyles.headerContent,
          maxWidth: "80rem",
          margin: "auto",
          display: "flex",
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",

          [ScreenSizes.smOnly]: {
            ...HeaderStyles.headerContent[ScreenSizes.smOnly],
            paddingLeft: "0.25rem",
            paddingRight: "0.25rem",
          },
          [ScreenSizes.mdOnly]: {
            ...HeaderStyles.headerContent[ScreenSizes.mdOnly],
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          },
          [ScreenSizes.mdAndBelow]: {
            ...HeaderStyles.headerContent[ScreenSizes.mdAndBelow],
            flexDirection: "column",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          },
        },
        section: {
          margin: "0 0.5rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          [ScreenSizes.smAndAbove]: {
            margin: "0 1rem",
          },
          [ScreenSizes.mdAndAbove]: {
            margin: "0 2rem",
          },
          [ScreenSizes.lgAndAbove]: { margin: 0, justifyContent: "flex-start" },
        },
        sectionLeft: {},
        sectionRight: {
          [ScreenSizes.mdAndBelow]: {
            display: "none",
          },
        },
      },
      transparent && {
        container: {
          background: "transparent",
        },
      },
    ],
    [transparent]
  );

  const overrideStyles = {
    navItem: {
      fontSize: "1rem",
      ":hover": {
        textShadow: "none",
      },
    },
    navItemActive: {
      fontSize: "1rem",
    },
  };

  const leftStyles = {
    navItem: {
      fontSize: "1rem",
      ":hover": {
        textShadow: "none",
      },
    },
    navItemActive: {
      fontSize: "1rem",
    },
    navItemOuter: {
      ":nth-child(2)": {
        [ScreenSizes.lgAndAbove]: {
          marginRight: "1rem",
          ":hover": {
            background: "var(--color-secondary-d4)",
          },
        },

        [ScreenSizes.xlAndBelow]: {
          marginTop: "0.225rem",
          marginRight: "0.78rem",
        },
        [ScreenSizes.mdAndBelow]: {
          marginTop: "0.4rem",
        },
        [createBreakpoint({ min: 768, max: 1400 })]: {
          marginRight: "0.6rem",
          marginBottom: "0.7rem",
        },
      },
    },
  };

  const navItemWhiteColor = {
    navTitle: {
      color: colours.bodyText,
    },
    navItem: {
      ":hover": {
        textShadow: "none",
      },
    },
  };

  const { styles } = useStyles(baseStyles, props);

  const { isWindowSizeOrMore } = useWindowSize();

  const { leftItems, rightItems } = useNavLinks();

  const renderNavItems = (items, side) =>
    items.reduce((contents, item, index) => {
      if (!item.minScreenSize || isWindowSizeOrMore(item.minScreenSize)) {
        contents.push(
          <NavMenuItem
            styles={side === "left" ? leftStyles : overrideStyles}
            labelStyles={switchToWhiteStyles ? navItemWhiteColor : {}}
            switchToWhiteStyles={switchToWhiteStyles}
            key={item.id || index}
            item={item}
          />
        );
      }

      return contents;
    }, []);

  const checkItemPermission = (item) =>
    (!item.permission || userHasPermission(user, item.permission)) &&
    (!item.noPermission || !userHasPermission(user, item.noPermission));

  return (
    <nav
      className={css(
        styles.container,
        switchToWhiteStyles && styles.whiteBackground
      )}
    >
      <div className={css(styles.inner)}>
        <div className={css(styles.section, styles.sectionLeft)}>
          {renderNavItems(leftItems.filter(checkItemPermission), "left")}
        </div>
        <div className={css(styles.section, styles.sectionRight)}>
          {renderNavItems(rightItems.filter(checkItemPermission), "right")}
        </div>
      </div>
    </nav>
  );
};

NavMenu.propTypes = {
  transparent: PropTypes.bool,
  user: PropTypes.instanceOf(Map),
};

NavMenu.defaultProps = {
  transparent: false,
  user: null,
};

export default NavMenu;
